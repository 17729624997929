import React from "react"
import {Link} from "gatsby"

import {FaPhoneAlt, FaEnvelope} from "react-icons/fa"
import Subscribe from "sections/Subscribe"
import {Container, Row, Col} from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import {List, ListItem} from "reusecore/List"
import {Link as OnepageLink} from "react-scroll"

import cryptikFooterLogo from "assets/images/logo-white.png"
import data from "assets/data/footer"
import FooterWrapper from "./footer.style"

const Footer = () => {
    return (
        <FooterWrapper>
            <Box className="footer-content-wrapper">
                <Subscribe/>
                <Container>
                    <Row>
                        <Col className="xs-12">
                            <Box className="footer-social-links">
                                {data.links.map((item, index) => (
                                    <Link  to={item.url} key={index} target="_blank">
                                        {item.icon}
                                    </Link>
                                ))}
                            </Box>

                            <Box className="copyright-text">
                                <Text>&copy; Potentiam | All right reserved 2021</Text>
                                <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                                <a href="/terms" target="_blank">Terms & Condition</a>
                                <a href="/termsOfUse" target="_blank">Terms of Use</a>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </FooterWrapper>
    )
}

export default Footer;

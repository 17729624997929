const Data = {
  menuItems: [
    {
      name: "Home",
      path: "home",
      offset: "-100",
    },
    {
      name: "About",
      path: "ourVision",
      offset: "-100",
    },
    {
      name: "Projects",
      path: "service",
      offset: "-120",
    },
    {
      name: "Token",
      path: "token",
      offset: "-100",
    },

    // {
    //   name: "Pages",
    //   path: "/blog-grid",
    //   subItems: [
    //     {
    //       name: "Blog Grid",
    //       path: "/blog-grid",
    //     },
    //     {
    //       name: "Blog List",
    //       path: "/blog-list",
    //     },
    //     {
    //       name: "Blog Full",
    //       path: "/blog-full",
    //     },
    //     {
    //       name: "Blog Single",
    //       path: "/blog-single",
    //     },
    //   ],
    // },
    {
      name: "FAQ",
      path: "faq",
      offset: "-60",
    },
    {
      name: "Contact",
      path: "contact",
      offset: "-70",
    },
  ],
}
export default Data

import React from "react"

import {Container, Row, Col} from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import Button from "reusecore/Button"
import Input from "reusecore/Form/Input"
import {SectionTitle} from "reusecore/SectionTitle"

import SubscribeWrapper from "./subscribe.style"

const Subscribe = () => {
    return (
        <SubscribeWrapper id="contact">
            <Box className="subscribe-box-wrapper">
                <Container>
                    <Row>
                        <Col className="col-12">
                            <Box className="subscribe-box-bg">
                                <Row>
                                    <Col className="lg-6 offset-lg-3 xs-12">
                                        <SectionTitle>
                                            <Heading> Sign Up For The Beta Test </Heading>

                                            <Text>
                                                Get ready for the Beta release. We will be rewarding our loyal followers
                                                with our proprietory PTM tokens.
                                                <Text className="ptm-token-color">
                                                    0x7c32db0645a259fae61353c1f891151a2e7f8c1e
                                                </Text>
                                            </Text>
                                        </SectionTitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="lg-8 offset-lg-2 xs-12">
                                        <Box className="form-box text-center">

                                            <Button
                                                onClick={() => window.open("https://publ.maillist-manage.com/ua/Optin?od=11287ecab2756d&zx=127734def&lD=15b2278c65de65c9&n=11699f750a556dd&sD=15b2278c65de65bf", "_blank")}>Sign
                                                up now</Button>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </SubscribeWrapper>
    )
}

export default Subscribe

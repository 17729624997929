import React from 'react';

import { 
    FaTwitter,
    FaTumblr,
    FaGooglePlusG,
    FaFacebookF,
    FaLinkedinIn,
    FaSlack,
    FaMediumM,
    FaTelegramPlane,
    FaSnapchatGhost,
    FaPinterestP,
    FaYoutube,
    FaInstagram,
  } from "react-icons/fa";

  export default {
    links : [
        {
            icon: <FaTwitter />,
            url: "https://twitter.com/potentiam_io"
        },
        {
            icon: <FaFacebookF />,
            url: "https://www.facebook.com/potentiam.io/"
        },
        {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/company/potentiam"
        },
        {
            icon: <FaTelegramPlane />,
            url: "https://t.me/potentiamgroup"
        },
        {
            icon: <FaInstagram/>,
            url: 'https://www.instagram.com/potentiam_io/'
        }
    ]
  }